import React, { Component } from "react";
import ".././css/theme.css";
import "./about.css";
import ".././assets/vendor/boxicons/css/boxicons.min.css";
import ".././assets/vendor/swiper/swiper-bundle.min.css";

class Demo extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log("Awards");
  }

  render() {
    return (
      <div className="card-body p-md-5 p-4 bg-size-cover">
        <div className="py-md-5 py-4 text-center">
          <h1>Interested in joining the team?</h1>
          <div className="pt-md-5 pt-4 pb-md-2">
            <a href="mailto:careers@aviai.io" className="btn btn-lg btn-info">
              Reach Out
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Demo;
