import React, { Component } from "react";
import ".././css/theme.css";
import "./about.css";
import ".././assets/vendor/boxicons/css/boxicons.min.css";
import ".././assets/vendor/swiper/swiper-bundle.min.css";
import ".././assets/vendor/lightgallery/css/lightgallery-bundle.min.css";
import vivek from ".././assets/img/team/viv.png"
import shara from ".././assets/img/team/shara2.jpg";
import brendan from ".././assets/img/team/brendan2.jpg";
import eugene from ".././assets/img/team/eugene2.jpg";
import juliano from ".././assets/img/team/juliano.jpg";
import aqib from ".././assets/img/team/aqib.jpg";
// import person7 from ".././assets/img/team/30.jpg";
// import person8 from ".././assets/img/team/31.jpg";

class Speaker extends Component {
  componentDidMount() {
    console.log("speaker");
  }

  render() {
    return (
      <>
        <div className="pb-xl-5">
          <h2 className="display-4">Meet The Crew</h2>
          <div className="d-md-flex align-items-md-start">
            <p className="col-md-6 col-sm-8 order-md-1 order-2 d-flex flex-column justify-content-between text-md-start text-center">
              We are a fast-growing, multi-disciplinary team
              of AI scientists and engineers working towards one shared goal
              of transforming how airlines operate.
            </p>
          </div>
        </div>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 mt-2 mt-lg-4">
          <div class="col">
            <div class="card card-hover border-0 bg-transparent">
              <div class="position-relative">
                <img src={vivek} class="rounded-3" alt="Ralph Edwards" />
                <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span class="position-absolute top-0 start-0 w-100 h-100 bg-primary opacity-35 rounded-3"></span>
                  <div class="position-relative d-flex zindex-2">
                    {/* <a
                      href="#"
                      class="btn btn-icon btn-secondary btn-facebook btn-sm bg-white me-2"
                    >
                      <i class="bx bxl-facebook"></i>
                    </a>
                    <a
                      href="#"
                      class="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2"
                    >
                      <i class="bx bxl-linkedin"></i>
                    </a>
                    <a
                      href="#"
                      class="btn btn-icon btn-secondary btn-twitter btn-sm bg-white"
                    >
                      <i class="bx bxl-twitter"></i>
                    </a> */}
                  </div>
                </div>
              </div>
              <div class="card-body text-center p-3">
                <h3 class="fs-lg fw-semibold pt-1 mb-2">Dr. Vivek Adarsh</h3>
                <p class="fs-sm mb-0">Founder &amp; CEO</p>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card card-hover border-0 bg-transparent">
              <div class="position-relative">
                <img src={shara} class="rounded-3" alt="Albert Flores" />
                <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span class="position-absolute top-0 start-0 w-100 h-100 bg-primary opacity-35 rounded-3"></span>
                  <div class="position-relative d-flex zindex-2">

                  </div>
                </div>
              </div>
              <div class="card-body text-center p-3">
                <h3 class="fs-lg fw-semibold pt-1 mb-2">Dr. Shara Balakrishnan</h3>
                <p class="fs-sm mb-0">Co-founder &amp; CTO</p>
              </div>
            </div>
          </div>


          <div class="col">
            <div class="card card-hover border-0 bg-transparent">
              <div class="position-relative">
                <img src={brendan} class="rounded-3" alt="Marvin McKinney" />
                <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span class="position-absolute top-0 start-0 w-100 h-100 bg-primary opacity-35 rounded-3"></span>
                  <div class="position-relative d-flex zindex-2">
                   
                  </div>
                </div>
              </div>
              <div class="card-body text-center p-3">
                <h3 class="fs-lg fw-semibold pt-1 mb-2">Brendan Shanny</h3>
                <p class="fs-sm mb-0">Product Lead</p>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card card-hover border-0 bg-transparent">
              <div class="position-relative">
                <img src={aqib} class="rounded-3" alt="Jenny Wilson" />
                <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span class="position-absolute top-0 start-0 w-100 h-100 bg-primary opacity-35 rounded-3"></span>
                  <div class="position-relative d-flex zindex-2">
                   
                  </div>
                </div>
              </div>
              <div class="card-body text-center p-3">
                <h3 class="fs-lg fw-semibold pt-1 mb-2">Dr. Aqib Hasnain</h3>
                <p class="fs-sm mb-0">Chief Scientist</p>
              </div>
            </div>
          </div>

          
           <div class="col"> 
            </div>
          <div class="col">
            <div class="card card-hover border-0 bg-transparent">
              <div class="position-relative">
                <img src={eugene} class="rounded-3" alt="Cameron Williamson" />
                <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span class="position-absolute top-0 start-0 w-100 h-100 bg-primary opacity-35 rounded-3"></span>
                  <div class="position-relative d-flex zindex-2">
                   
                  </div>
                </div>
              </div>
              <div class="card-body text-center p-3">
                <h3 class="fs-lg fw-semibold pt-1 mb-2">Eugene Gimelberg</h3>
                <p class="fs-sm mb-0">Engineering Lead</p>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card card-hover border-0 bg-transparent">
              <div class="position-relative">
                <img src={juliano} class="rounded-3" alt="Jerome Bell" />
                <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span class="position-absolute top-0 start-0 w-100 h-100 bg-primary opacity-35 rounded-3"></span>
                  <div class="position-relative d-flex zindex-2">
                   
                  </div>
                </div>
              </div>
              <div class="card-body text-center p-3">
                <h3 class="fs-lg fw-semibold pt-1 mb-2">Juliano Dill</h3>
                <p class="fs-sm mb-0">Product Designer</p>
              </div>
            </div>
          </div>

          {/* <div class="col">
            <div class="card card-hover border-0 bg-transparent">
              <div class="position-relative">
                <img src={person8} class="rounded-3" alt="Darrell Steward" />
                <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span class="position-absolute top-0 start-0 w-100 h-100 bg-primary opacity-35 rounded-3"></span>
                  <div class="position-relative d-flex zindex-2">
                    <a
                      href="#"
                      class="btn btn-icon btn-secondary btn-facebook btn-sm bg-white me-2"
                    >
                      <i class="bx bxl-facebook"></i>
                    </a>
                    <a
                      href="#"
                      class="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2"
                    >
                      <i class="bx bxl-linkedin"></i>
                    </a>
                    <a
                      href="#"
                      class="btn btn-icon btn-secondary btn-twitter btn-sm bg-white"
                    >
                      <i class="bx bxl-twitter"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="card-body text-center p-3">
                <h3 class="fs-lg fw-semibold pt-1 mb-2">Darrell Steward</h3>
                <p class="fs-sm mb-0">Senior Project Manager, Uber</p>
              </div>
            </div>
          </div> */}
        </div>
      </>
    );
  }
}

export default Speaker;
