import React, { Component } from "react";
import logo from ".././img/avai-icon.svg";
import ".././css/theme.css";
import "./global.css";
import ".././assets/vendor/boxicons/css/boxicons.min.css";
import ".././assets/vendor/swiper/swiper-bundle.min.css";
import ".././assets/vendor/lightgallery/css/lightgallery-bundle.min.css";

class EndBar extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log("Success");
  }

  render() {
    return (
      <footer className="footer dark-mode bg-dark border-top border-light pt-5 pb-4 pb-lg-5">
        <div className="container pt-lg-4">
          <div className="row pb-5 text-sm-center text-md-start">
            <div className="col-lg-4 col-md-6">
              <div className="navbar-brand text-dark p-0 me-0 mb-3 mb-lg-4 justify-content-md-start justify-content-sm-center">
                <img src={logo} width="47" alt="Silicon" />
                {/* Avi.AI */}
              </div>
              <p className="fs-sm pb-lg-3 mb-4">
                We build innovative AI solutions that lead to sustainable change
                and growth across the aviation industry.
              </p>
              <p className="nav d-block fs-xs text-center text-md-start pb-2 pb-lg-0 mb-0">
                &copy; All rights reserved.
                <a
                  className="nav-link d-inline-block p-0"
                  target="_blank"
                  rel="noopener"
                >
                 Avi.AI Inc.
                </a>
              </p>
              {/* <form className="needs-validation" noValidate>
                <label htmlFor="subscr-email" className="form-label">
                  Subscribe to our newsletter
                </label>
                <div className="input-group">
                  <input
                    type="email"
                    id="subscr-email"
                    className="form-control rounded-start ps-5"
                    placeholder="Your email"
                    required
                  />
                  <i className="bx bx-envelope fs-lg text-muted position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>
                  <div className="invalid-tooltip position-absolute top-100 start-0">
                    Please provide a valid email address.
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Subscribe
                  </button>
                </div>
              </form> */}
            </div>
            <div className="col-xl-6 col-lg-7 col-md-5 offset-xl-2 offset-md-1 pt-4 pt-md-1 pt-lg-0">
              <div id="footer-links" className="row">
                <div className="col-lg-4">
                  <h6 className="mb-2">
                    <a
                      href="#useful-links"
                      className="d-block text-dark mb-2"
                    >
                      Useful Links
                    </a>
                  </h6>
                  <div
                    id="useful-links"
                    className="d-lg-block"
                  >
                    <ul className="nav flex-column pb-lg-1 mb-lg-3">
                      <li className="nav-item">
                        <a
                          href="/home"
                          className="nav-link d-inline-block px-0 pt-1 pb-2"
                        >
                          Home
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="/contact"
                          className="nav-link d-inline-block px-0 pt-1 pb-2"
                        >
                          Get in Touch
                        </a>
                      </li>
                      
                    </ul>
                    <ul className="nav flex-column mb-2 mb-lg-0">
                      <li className="nav-item">
                        <a
                          href="https://www.freeprivacypolicy.com/live/f9019af2-8af5-4b96-95fa-147cbb782718"
                          className="nav-link d-inline-block px-0 pt-1 pb-2"
                        >
                          Privacy Policy
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              
                <div className="col-xl-4 col-lg-5 pt-2 pt-lg-0">
                  <h6 className="mb-2">Contact Us</h6>
                  <a href="mailto:hey@aviai.io" className="fw-medium">
                    hey@aviai.io
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default EndBar;
