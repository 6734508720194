import React, { useState } from "react";
import axios from "axios";
import NavBar from "../global/navbar";
import EndBar from "../global/endbar";
import ".././css/theme.css";
import "./error.css";
import ".././assets/vendor/boxicons/css/boxicons.min.css";
import ".././assets/vendor/swiper/swiper-bundle.min.css";
import ".././assets/vendor/lightgallery/css/lightgallery-bundle.min.css";

function Touch() {

  const [contactField, setContactField] = useState({
    name: '',
    email: '',
    message: ''
  })

  const submitButton = document.querySelector('button[type="submit"]')

  const changeHandler = (e) => {
    setContactField(prevState => ({ ...prevState, [e.target.id]: e.target.value }))

    if (submitButton.innerText === 'Submitted!') {
      submitButton.innerText = 'Contact Us'
      submitButton.removeAttribute('disabled')
    }
  }

  const submitHandler = (e) => {
    e.preventDefault();
    submitButton.innerText = 'Submitted!'
    submitButton.setAttribute('disabled', true);
    document.querySelectorAll('.form-control').forEach(el => {
      el.value = '';
    })

    axios.post('https://sheet.best/api/sheets/1159838e-72b1-4fec-8d79-31b5eb7fdfb6', contactField);
  }

  return (
    <>
      <title>Avi.AI Inc. | Contact</title>

      <NavBar />
      <section
        className="bg-size-cover bg-position-bottom-center bg-repeat-0 py-5"
        style={{ backgroundImage: `linear-gradient( #000, 80%, #21298E)` }}
      >
        <div className="container pt-5 pb-lg-4 pb-xl-5">
          <div className="row pt-md-2 pt-lg-5 pb-2 pb-md-4">
            <div className="col-xxl-4 col-xl-5 col-lg-6 pt-3 mt-3">
              <h1 className="h3 mb-2">Want to work with us?</h1>
              <h2 className="display-1 text-gradient-primary pb-sm-2 pb-md-3 mb-3">
                Let's talk!
              </h2>
            </div>
            <div className="col-lg-6 offset-xl-1 offset-xxl-2 pt-3 pt-md-4 pt-lg-3 mt-3">
              <form className="needs-validation" novalidate onSubmit={submitHandler}>
                <div className="row g-4">
                  <div className="col-sm-6">
                    <label for="fn" className="form-label fs-base">
                      Full name
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="name"
                      required
                      onChange={changeHandler}
                    />
                    <div className="invalid-feedback">
                      Please enter your full name!
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <label for="email" className="form-label fs-base">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      id="email"
                      required
                      onChange={changeHandler}
                    />
                    <div className="invalid-feedback">
                      Please provide a valid email address!
                    </div>
                  </div>
                  <div className="col-12 pb-2">
                    <label for="message" className="form-label fs-base">
                      Message
                    </label>
                    <textarea
                      className="form-control form-control-lg"
                      id="message"
                      rows="3"
                      required
                      onChange={changeHandler}
                    ></textarea>
                    <div className="invalid-feedback">
                      Please provide a valid email address!
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary w-100 w-sm-auto"
                    >
                      Contact Us
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <EndBar />
    </>
  );
}

export default Touch;
