import React, { Component } from "react";
import ".././css/theme.css";
import "./about.css";
import ".././assets/vendor/boxicons/css/boxicons.min.css";
import ".././assets/vendor/swiper/swiper-bundle.min.css";
import ".././assets/vendor/lightgallery/css/lightgallery-bundle.min.css";
import animation from ".././assets/json/animation-digital-agency.json";
import Lottie from "react-lottie-player";

class Splash extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log("Rawr");
  }

  render() {
    return (
      <div className="container position-relative zindex-5 py-5">
        <div className="row justify-content-md-start justify-content-center">
          <div className="col-md-6 col-sm-8 order-md-1 order-2 d-flex flex-column justify-content-between mt-4 pt-2 text-md-start text-center">
            <div className="mb-md-5 pb-xl-5 mb-4">
              <div className="d-inline-flex align-items-center position-relative mb-3">
                <h4 className="mb-0"></h4>
              </div>
              <h1 className="display-4 mb-md-5 mt-5">
                A<span className="text-gradient-primary"> Purpose-Built </span>
                Team
              </h1>
              <div className="d-md-flex align-items-md-start">
              </div>
            </div>

            {/* <div className="d-inline-flex align-items-center justify-content-center justify-content-md-start position-relative">
              <a
                href="#benefits"
                data-scroll
                data-scroll-offset="100"
                className="btn btn-video btn-icon rounded-circle shadow-sm flex-shrink-0 stretched-link me-3"
              >
                <i className="bx bx-chevron-down"></i>
              </a>
              <span className="fs-sm">Discover more</span>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Splash;
