import React, { Component } from "react";
import ".././css/theme.css";
import "./landing.css";
import ".././assets/vendor/boxicons/css/boxicons.min.css";
import ".././assets/vendor/swiper/swiper-bundle.min.css";
import dashboard from ".././assets/img/landing/combinedDashboard.png";
import Rellax from '.././assets/vendor/rellax/rellax.min.js';
import jarallax from '.././assets/vendor/jarallax/src/core'

class Aether extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log("Aether");

    new Rellax(".rellax a", { // <---- Via class name
      enter: true,
      callback: function (positions) {
        // callback every position change
        console.log(positions);
      }
    });

    jarallax(document.querySelectorAll('.jarallax'), {
      speed: 0.2,
    });
  }

  render() {


    return (
      <>
        <div className="container position-relative zindex-5">
          <div className="row justify-content-center">
            <div className="order-md-1 order-2 d-flex flex-column justify-content-between mt-4 pt-2 text-center">
              <div className="mb-md-5 pb-xl-5 mb-4">
                <div className="d-inline-flex align-items-center position-relative">
                  <h1 className="display-4 mb-2">
                    <div class="d-flex justify-content-center">
                      <div class="rellax display-1 a  px-1" data-rellax-percentage="0.5" data-rellax-speed="-1" data-rellax-max="0">A</div>
                      <div class="rellax display-1 e px-1" data-rellax-percentage="0.5" data-rellax-speed="1" data-rellax-min="0">E</div>
                      <div class="rellax display-1 t px-1" data-rellax-percentage="0.5" data-rellax-speed="-1" data-rellax-max="0">T</div>
                      <div class="rellax display-1 h px-1" data-rellax-percentage="0.5" data-rellax-speed="1" data-rellax-min="0">H</div>
                      <div class="rellax display-1 e px-1" data-rellax-percentage="0.5" data-rellax-speed="-1" data-rellax-max="0">E</div>
                      <div class="rellax display-1 r px-1" data-rellax-percentage="0.5" data-rellax-speed="1" data-rellax-min="0">R</div>
                    </div>
                  </h1>
                </div>
                <p className="fs-3  mb-2" style={{ letterSpacing: ".4rem"}}>
                TARGETED. NIMBLE. POWERFUL
                </p>
                <br />
                <p className="fs-4 mb-2">
                  Our Aether platform is command central for your airside operations. The
                  platform is fueled by decades of experience in AI problem-solving,
                  and a keen emphasis on collaboration makes Aether a flexible tool that addresses
                  specific operational target areas — all with impeccable accuracy.
                </p>
              </div>

              {/* <!-- Portfolio grid view: Card style 2 --> */}
              <a class="card card-portfolio bg-transparent border-0 dashboard-container">
                <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span class="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-50 rounded-3"></span>
                  <div class="position-relative zindex-2">
                    <p class="display-4 text-light mb-2">Tuned to Your Priorities</p>
                    <span class="fs-4 text-light opacity-100">We work with you to make sure that
                      AETHER delivers key insights on the
                      operations that need it the most.</span>
                  </div>
                </div>
                <div class="dashboard-image position-relative" src={dashboard} alt="dashboard">
                  <img src={dashboard} class="rounded-3" alt="Image"></img>
                </div>
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Aether;
