import React, {useEffect}  from "react";

import NavBar from ".././global/navbar";
import Splash from ".//splash";
import Aether from "./aether";
import Features from "./features";
import System from "./system";


import EndBar from "../global/endbar";
import Rellax from ".././assets/vendor/rellax/rellax.min.js";


function Landing() {
  useEffect(() => {
    new Rellax(".rellax", { // <---- Via class name
      speed: -10,
      center: false,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false
    });
  });
  return (
    <>
      <title>Avi.AI Inc.</title>
      <NavBar />
      <section
        className=" d-flex align-items-center min-vh-100 bg-light mb-5 py-lg-5 pt-5 splash-section"
        // style={{ backgroundImage: `linear-gradient( #0b0f19, 100%, #000000)` }}
        style={{ backgroundImage: `linear-gradient( #000000, 100%, #000000)` }}
      >
        <Splash />
      </section>
      <section className="container dashboard-section mb-5 pt-lg-5">
        <Aether />
      </section>
      <section>
      </section>
      {/* <section >
        <div class="d-flex justify-content-evenly" style={{ padding: "3rem 0" }}>
          <div>
            <p class="text-primary d-lg-block mb-2">FLIGHT PLAN</p>
            <h1>Tuned to
              Your Priorities</h1>
            We work with you to make sure that
            AETHER delivers key insights on the
            <p>  operations that need it the most.</p>
          </div>
          <div>
            <div class="list-group">
              <p class="list-group-item list-group-item-action"> Missed Connection </p>
              <p class="list-group-item list-group-item-action">Flight Delay</p>
              <p class="list-group-item list-group-item-action active">Baggage Mishandling</p>
              <p class="list-group-item list-group-item-action">Disruption Management</p>
              <p class="list-group-item list-group-item-action">Ancillary Service Prediction</p>
            </div>
          </div>
        </div>
      </section> */}
      <section className="container mb-5 pt-lg-5" >
        <Features />
      </section>
      <section className="container mb-5 pt-lg-5" >
        <System />
      </section>
      {/* <section class="" style={{alignSelf: "center", padding: "3rem"}}>
        <div class="block">
          <div class="card feature">
            <div class="card-body">
              <div class="card-header border-none">EYE ON THE SKY</div>
              <h3 class="card-title">Won't stay still</h3>
              <p>
                Aviation connects the world and it will
                continue to evolve. Air-side operations will
                do the same, and so should your
                intelligence platform. AviAI is the cutting
                edge of operational intelligence for those
                that fly.
              </p>
            </div>
            <img src={plane} className="" alt="AI Plane" />
          </div>
        </div>
      </section> */}
      <section class="pb-5" style={{textAlign: 'center', backgroundImage: `linear-gradient( #000000, 50%, #21298e)`, padding: '200px'}}>
        <h1 class="display-5">Ready for takeoff?</h1>
        <br />
        <a href="/contact">
        <button type="button" class="btn btn-info shadow-info fs-6">Talk to us</button>
        </a>

      </section>

      {/* <section class="position-relative overflow-hidden">
        <Animation />
      </section>
      <section class="position-relative py-5">
        <Tooltip />
      </section> */}
      {/* <section
        className="container pt-3 pt-md-4 pt-lg-5 pb-2 mt-lg-2 mt-xl-4"
        id="funmetric"
      >
        <MissionGoals />
      </section>
      <section className="container mb-5 pt-lg-5" id="benefits">
        <Benefits />
      </section>
      <section className="container pb-4 pb-lg-5 mb-3">
        <Clients />
      </section>
      <section className="container pb-4 pb-lg-5 mb-3">
        <Awards />
      </section>
    */}
      {/* <section className="container pt-3 pb-4 pb-md-5">
        <Demo />
      </section> */}
      <EndBar />
    </>
  );
}

export default Landing;
