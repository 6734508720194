import React, { Component } from "react";
import ".././css/theme.css";
import "./landing.css";
import ".././assets/vendor/boxicons/css/boxicons.min.css";
import ".././assets/vendor/swiper/swiper-bundle.min.css";
import ".././assets/vendor/lightgallery/css/lightgallery-bundle.min.css";



class Splash extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log("Rawr");
  }

  render() {
    return (
      <div className="container position-relative zindex-5 py-5">
        <div className="row justify-content-md-start justify-content-center">
          <div className="col-md-6 col-sm-8 order-md-1 order-2 d-flex flex-column justify-content-around mt-4 pt-2 text-md-start text-center">
            <div className="mb-md-5 pb-xl-5 mb-4">
              <div className="d-inline-flex align-items-center position-relative mb-3">
                <h5 className="mb-0">
                </h5>
              </div>
              <h1 className="display-2 mb-md-5 mb-3 pb-3"
              // style="font-size:2.25rem;"
              >
                Whip-Smart
                <br />
                <span className="text-gradient-primary">
                  Operational Intelligence
                </span>
              </h1>
              <div className="align-items-md-start">
                <p className="fs-4 mb-1">
                  Avi.AI enables <b>10X faster</b><br /> operational decision making <br />through its cutting-edge AI platform
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 order-md-2 order-1 p-0">
            <div className="hero-video-container margin-4 p-0">
              <div data-poster-url="https://uploads-ssl.webflow.com/61cf72b05dba90adc5370255/61d8762443b3e68038bdc4eb_Hero Video Uncompressed-poster-00001.jpg" data-video-urls="https://uploads-ssl.webflow.com/61cf72b05dba90adc5370255/61d8762443b3e68038bdc4eb_Hero Video Uncompressed-transcode.mp4,https://uploads-ssl.webflow.com/61cf72b05dba90adc5370255/61d8762443b3e68038bdc4eb_Hero Video Uncompressed-transcode.webm" data-autoplay="true" data-loop="true" data-wf-ignore="true" className="background-video w-background-video w-background-video-atom">
                <video id="632aa9b7-c5d7-daf2-ede5-e1bd9e3f716e-video" autoPlay loop muted data-wf-ignore="true" data-object-fit="cover">
                  <source src="https://uploads-ssl.webflow.com/61cf72b05dba90adc5370255/61d8762443b3e68038bdc4eb_Hero Video Uncompressed-transcode.mp4" data-wf-ignore="true" />
                  <source src="https://uploads-ssl.webflow.com/61cf72b05dba90adc5370255/61d8762443b3e68038bdc4eb_Hero Video Uncompressed-transcode.webm" data-wf-ignore="true" />
                </video>
              </div>
            </div>

          </div>
        </div>
        <section className="container dashboard-section mb-5 pt-lg-5"></section>
        <div style={{ 
          height: '3px', // Adjust height as necessary
          background: 'linear-gradient(to right, #000000, #FFF, #000000)', // Replace with your gradient values
          margin: '0px 300px', // This adds vertical spacing, adjust as necessary
          marginTop: '200px'
        }} />
        <div style={{color: 'white', textAlign: 'center', padding: '30px 0', marginTop: '100px'}}>
          <h2>Trusted by world's largest airlines</h2>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', gap: '30px', padding: '20px' }}>

            <img src={process.env.PUBLIC_URL + '/united.png'} alt="UAL" style={{ height: '50px' }} />
            <img src={process.env.PUBLIC_URL + '/delta.png'} alt="DAL" style={{ height: '40px' }} />
            <img src={process.env.PUBLIC_URL + '/lufthansa.png'} alt="LH" style={{ height: '50px' }} />
            <img src={process.env.PUBLIC_URL + '/AFB.png'} alt="AF" style={{ height: '30px' }} />

          </div>
        </div>
      </div>
    );
  }
}

export default Splash;
