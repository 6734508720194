import React, { Component } from "react";
import ".././css/theme.css";
import "./global.css";
import ".././assets/vendor/boxicons/css/boxicons.min.css";
import ".././assets/vendor/swiper/swiper-bundle.min.css";
import ".././assets/vendor/lightgallery/css/lightgallery-bundle.min.css";
import horilogo from ".././assets/img/horilogo.svg";

class NavBar extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log("Success");
  }

  render() {
    return (
      <header className="header navbar navbar-expand-lg bg-light position-absolute navbar-sticky">
        <div className="container px-3">
          <a href="/home" className="navbar-brand pe-3">
            <img src={horilogo} width="125" alt="Avi.AI logo" />
          </a>
          <div id="navbarNav" className="offcanvas offcanvas-end">
            <div className="offcanvas-header border-bottom">
              <h5 className="offcanvas-title">Menu</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {/* <li className="nav-item dropdown">
                  <a
                    href="#"
                    className="nav-link dropdown-toggle active"
                    data-bs-toggle="dropdown"
                    aria-current="page"
                  >
                    Why AVIAI?
                  </a>
                  <div className="dropdown-menu p-0">
                    <div className="d-lg-flex">
                      <div className="mega-dropdown-column pt-lg-3 pb-lg-4">
                        <ul className="list-unstyled mb-0">
                          <li>
                            <a href="/" className="dropdown-item">
                              Template Intro Page
                            </a>
                          </li>
                          <li>
                            <a href="about.html" className="dropdown-item">
                              Mobile App Showcase v.1
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="mega-dropdown-column pt-lg-3 pb-lg-4">
                        <ul className="list-unstyled mb-0">
                          <li>
                            <a href="index.html" className="dropdown-item">
                              Medical
                            </a>
                          </li>
                          <li>
                            <a href="about.html" className="dropdown-item">
                              IT (Software) Company
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li> */}
                {/* <li className="nav-item dropdown">
                  <a
                    href="#"
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    About
                  </a>
                  <div className="dropdown-menu">
                    <div className="d-lg-flex pt-lg-3">
                      <div className="mega-dropdown-column">
                        <h6 className="px-3 mb-2">About</h6>
                        <ul className="list-unstyled mb-3">
                          <li>
                            <a href="about.html" className="dropdown-item py-1">
                              About v.1
                            </a>
                          </li>
                          <li>
                            <a href="about.html" className="dropdown-item py-1">
                              About v.2
                            </a>
                          </li>
                        </ul>
                        <h6 className="px-3 mb-2">Blog</h6>
                        <ul className="list-unstyled mb-3">
                          <li>
                            <a href="index.html" className="dropdown-item py-1">
                              List View with Sidebar
                            </a>
                          </li>
                          <li>
                            <a href="about.html" className="dropdown-item py-1">
                              Grid View with Sidebar
                            </a>
                          </li>
                          <li>
                            <a href="about.html" className="dropdown-item py-1">
                              List View no Sidebar
                            </a>
                          </li>
                          <li>
                            <a href="about.html" className="dropdown-item py-1">
                              Grid View no Sidebar
                            </a>
                          </li>
                          <li>
                            <a href="about.html" className="dropdown-item py-1">
                              Simple Feed
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li> */}
                {/* <li className="nav-item dropdown">
                  <a
                    href="#"
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    Solutions
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a href="index.html" className="dropdown-item">
                        Account Details
                      </a>
                    </li>
                    <li>
                      <a href="about.html" className="dropdown-item">
                        Security
                      </a>
                    </li>
                  </ul>
                </li> */}
                {/* <li className="nav-item">
                  <a href="/product" className="nav-link">
                    Product
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/404-v2" className="nav-link">
                    Implementation
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/about" className="nav-link">
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/careers" className="nav-link">
                    Careers
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/blog" className="nav-link">
                    Blog
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/contact" className="nav-link">
                    Get in Touch
                  </a>
                </li> */}
              </ul>
            </div>
            <div className="offcanvas-header border-top">
              <button>Wow</button>
            </div>
          </div>
          {/* <div
            className="form-check form-switch mode-switch pe-lg-1 ms-auto me-4"
            data-bs-toggle="mode"
          >
            <input
              type="checkbox"
              className="form-check-input"
              id="theme-mode"
            />
            <label
              className="form-check-label d-none d-sm-block"
              htmlFor="theme-mode"
            >
              Light
            </label>
            <label
              className="form-check-label d-none d-sm-block"
              htmlFor="theme-mode"
            >
              Dark
            </label>
          </div> */}
          {/* <button
            type="button"
            className="navbar-toggler"
            data-bs-toggle="offcanvas"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class='bx bx-envelope'></i>
          </button> */}
          <a href="/about" style={{textDecoration: 'none'}}>
          <button type="button" className="btn fs-6 no-border-hover">
            About Us
          </button>
          </a>
          <a href="/contact" style={{textDecoration: 'none'}}>
          <button type="button" className="btn btn-outline-light mb-2 mb-lg-0 fs-7" style={{marginTop: '0.3rem'}}>
            Get in Touch&nbsp;&nbsp;<i class='bx bx-envelope'></i>
            {/* <span style="font-size:smaller;"> Get in Touch</span>&nbsp;&nbsp;<i class='bx bx-envelope'></i> */}
          </button>
          </a>
        </div>
      </header>
    );
  }
}

export default NavBar;
