import React, { Component } from "react";
import ".././css/theme.css";
import "./about.css";
import ".././assets/vendor/boxicons/css/boxicons.min.css";
import ".././assets/vendor/swiper/swiper-bundle.min.css";
import ".././assets/vendor/lightgallery/css/lightgallery-bundle.min.css";
import "swiper/css";
import { SwiperSlide, Swiper } from "swiper/react";
import person1 from ".././assets/img/team/vivek.png";
import person2 from ".././assets/img/team/11.jpg";
import person3 from ".././assets/img/team/12.jpg";
import person4 from ".././assets/img/team/13.jpg";
import person5 from ".././assets/img/team/14.jpg";
import person6 from ".././assets/img/team/15.jpg";
import person7 from ".././assets/img/team/16.jpg";

class People extends Component {
  render() {
    return (
      <>
        <h2 class="h1 text-center pb-md-1 mb-1 mb-sm-3">Our Leaders</h2>
        <Swiper
          className="mx-0 mb-md-n2 mb-xxl-n3"
          slidesPerView={0}
          spaceBetween={8}
          pagination={{
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
          }}
          breakpoints={{
            480: { slidesPerView: 2 },
            700: { slidesPerView: 3 },
            900: { slidesPerView: 4 },
            // 1160: { slidesPerView: 5 },
            // 1500: { slidesPerView: 6 },
          }}
        >
          <div class="swiper-wrapper">
            <SwiperSlide>
              <div class="card card-body card-hover bg-light border-0 text-center mx-2">
                <img
                  src={person1}
                  class="d-block rounded-circle mx-auto mb-3"
                  width="162"
                  alt="Ralph Edwards"
                />
                <h5 class="fw-medium fs-lg mb-1">Vivek Adarsh</h5>
                <p class="fs-sm mb-3">Co-Founder</p>
                <div class="d-flex justify-content-center">
                  <a
                    href="#"
                    class="btn btn-icon btn-outline-secondary btn-facebook btn-sm me-2"
                  >
                    <i class="bx bxl-facebook"></i>
                  </a>
                  <a
                    href="#"
                    class="btn btn-icon btn-outline-secondary btn-linkedin btn-sm me-2"
                  >
                    <i class="bx bxl-linkedin"></i>
                  </a>
                  <a
                    href="#"
                    class="btn btn-icon btn-outline-secondary btn-twitter btn-sm"
                  >
                    <i class="bx bxl-twitter"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>

            {/* <SwiperSlide>
              <div class="card card-body card-hover bg-light border-0 text-center mx-2">
                <img
                  src={person2}
                  class="d-block rounded-circle mx-auto mb-3"
                  width="162"
                  alt="Darrell Steward"
                />
                <h5 class="fw-medium fs-lg mb-1">Paul U</h5>
                <p class="fs-sm mb-3">Co-Founder</p>
                <div class="d-flex justify-content-center">
                  <a
                    href="#"
                    class="btn btn-icon btn-outline-secondary btn-facebook btn-sm me-2"
                  >
                    <i class="bx bxl-facebook"></i>
                  </a>
                  <a
                    href="#"
                    class="btn btn-icon btn-outline-secondary btn-stack-overflow btn-sm me-2"
                  >
                    <i class="bx bxl-stack-overflow"></i>
                  </a>
                  <a
                    href="#"
                    class="btn btn-icon btn-outline-secondary btn-github btn-sm"
                  >
                    <i class="bx bxl-github"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div class="card card-body card-hover bg-light border-0 text-center mx-2">
                <img
                  src={person3}
                  class="d-block rounded-circle mx-auto mb-3"
                  width="162"
                  alt="Cameron Williamson"
                />
                <h5 class="fw-medium fs-lg mb-1">Michael Zhang</h5>
                <p class="fs-sm mb-3">Software Web Developer</p>
                <div class="d-flex justify-content-center">
                  <a
                    href="#"
                    class="btn btn-icon btn-outline-secondary btn-facebook btn-sm me-2"
                  >
                    <i class="bx bxl-facebook"></i>
                  </a>
                  <a
                    href="#"
                    class="btn btn-icon btn-outline-secondary btn-dribbble btn-sm me-2"
                  >
                    <i class="bx bxl-dribbble"></i>
                  </a>
                  <a
                    href="#"
                    class="btn btn-icon btn-outline-secondary btn-linkedin btn-sm"
                  >
                    <i class="bx bxl-linkedin"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div class="card card-body card-hover bg-light border-0 text-center mx-2">
                <img
                  src={person4}
                  class="d-block rounded-circle mx-auto mb-3"
                  width="162"
                  alt="Jerome Bell"
                />
                <h5 class="fw-medium fs-lg mb-1">Rob Kazemarek</h5>
                <p class="fs-sm mb-3">Marketing Director</p>
                <div class="d-flex justify-content-center">
                  <a
                    href="#"
                    class="btn btn-icon btn-outline-secondary btn-instagram btn-sm me-2"
                  >
                    <i class="bx bxl-instagram"></i>
                  </a>
                  <a
                    href="#"
                    class="btn btn-icon btn-outline-secondary btn-linkedin btn-sm"
                  >
                    <i class="bx bxl-linkedin"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide class="swiper-slide py-3">
              <div class="card card-body card-hover bg-light border-0 text-center mx-2">
                <img
                  src={person5}
                  class="d-block rounded-circle mx-auto mb-3"
                  width="162"
                  alt="Annette Black"
                />
                <h5 class="fw-medium fs-lg mb-1">Sherri Lynn Conklin</h5>
                <p class="fs-sm mb-3">Acting CEO</p>
                <div class="d-flex justify-content-center">
                  <a
                    href="#"
                    class="btn btn-icon btn-outline-secondary btn-facebook btn-sm me-2"
                  >
                    <i class="bx bxl-facebook"></i>
                  </a>
                  <a
                    href="#"
                    class="btn btn-icon btn-outline-secondary btn-dribbble btn-sm me-2"
                  >
                    <i class="bx bxl-dribbble"></i>
                  </a>
                  <a
                    href="#"
                    class="btn btn-icon btn-outline-secondary btn-behance btn-sm"
                  >
                    <i class="bx bxl-behance"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide class="swiper-slide py-3">
              <div class="card card-body card-hover bg-light border-0 text-center mx-2">
                <img
                  src={person6}
                  class="d-block rounded-circle mx-auto mb-3"
                  width="162"
                  alt="Marvin McKinney"
                />
                <h5 class="fw-medium fs-lg mb-1">Marvin McKinney</h5>
                <p class="fs-sm mb-3">Senior Project Manager</p>
                <div class="d-flex justify-content-center">
                  <a
                    href="#"
                    class="btn btn-icon btn-outline-secondary btn-facebook btn-sm me-2"
                  >
                    <i class="bx bxl-facebook"></i>
                  </a>
                  <a
                    href="#"
                    class="btn btn-icon btn-outline-secondary btn-linkedin btn-sm me-2"
                  >
                    <i class="bx bxl-linkedin"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide> */}
          </div>

          <div class="swiper-pagination position-relative pt-3 mt-3"></div>
        </Swiper>
      </>
    );
  }
}

export default People;
