import React, { Component } from "react";
import ".././css/theme.css";
import "./about.css";
import ".././assets/vendor/boxicons/css/boxicons.min.css";
import ".././assets/vendor/swiper/swiper-bundle.min.css";
import ".././assets/vendor/lightgallery/css/lightgallery-bundle.min.css";
import headset from ".././assets/img/about/icons/headset-light.svg";
import chat from ".././assets/img/about/icons/chat-light.svg";
import group from ".././assets/img/about/icons/add-group-light.svg";
import location from ".././assets/img/about/icons/location-light.svg";

class Stats extends Component {
  render() {
    return (
      <div class="bg-secondary rounded-3 py-5 px-3 px-md-0">
        <div class="row justify-content-center mb-2 py-md-2 py-lg-4">
          <div class="col-lg-10 col-md-11">
            <h2 class="pb-3">Overpass by Numbers</h2>
            <div class="row row-cols-1 row-cols-md-2 g-4">
              <div class="col">
                <div class="card h-100 border-0 shadow-sm p-md-2 p-lg-4">
                  <div class="card-body d-flex align-items-center">
                    <div class="bg-primary shadow-primary rounded-3 flex-shrink-0 p-3">
                      <img
                        src={headset}
                        class="d-block m-1"
                        width="40"
                        alt="Icon"
                      />
                    </div>
                    <div class="ps-4 ms-lg-3">
                      <h3 class="display-5 mb-1">2,480</h3>
                      <p class="mb-0">
                        <span class="fw-semibold">Remote</span> Professionals
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="card h-100 border-0 shadow-sm p-md-2 p-lg-4">
                  <div class="card-body d-flex align-items-center">
                    <div class="bg-primary shadow-primary rounded-3 flex-shrink-0 p-3">
                      <img
                        src={chat}
                        class="d-block m-1"
                        width="40"
                        alt="Icon"
                      />
                    </div>
                    <div class="ps-4 ms-lg-3">
                      <h3 class="display-5 mb-1">1,200</h3>
                      <p class="mb-0">
                        <span class="fw-semibold">Requests</span> per Week
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="card h-100 border-0 shadow-sm p-md-2 p-lg-4">
                  <div class="card-body d-flex align-items-center">
                    <div class="bg-primary shadow-primary rounded-3 flex-shrink-0 p-3">
                      <img
                        src={group}
                        class="d-block m-1"
                        width="40"
                        alt="Icon"
                      />
                    </div>
                    <div class="ps-4 ms-lg-3">
                      <h3 class="display-5 mb-1">760</h3>
                      <p class="mb-0">
                        <span class="fw-semibold">New Clients</span> per Month
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="card h-100 border-0 shadow-sm p-md-2 p-lg-4">
                  <div class="card-body d-flex align-items-center">
                    <div class="bg-primary shadow-primary rounded-3 flex-shrink-0 p-3">
                      <img
                        src={location}
                        class="d-block m-1"
                        width="40"
                        alt="Icon"
                      />
                    </div>
                    <div class="ps-4 ms-lg-3">
                      <h3 class="display-5 mb-1">58</h3>
                      <p class="mb-0">
                        <span class="fw-semibold">Countries</span> Using Our
                        Product
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Stats;
