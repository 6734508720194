import React, { Component } from "react";
import ".././css/theme.css";
import "./landing.css";
import ".././assets/vendor/boxicons/css/boxicons.min.css";
import ".././assets/vendor/swiper/swiper-bundle.min.css";



class Features extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log("Features");
  }

  render() {
    return (
      <>
        <div class="d-flex flex-md-row flex-column">
        <div class="features-name text-primary">
        <span className="fs-4 mb-2">FEATURES</span>
        </div>
        <div id="wrapper">
          <div class="block wide">
            <div class="card card-hover">
              <div class="card-body">
                <h3 class="card-title">Continuous Real-time
                  Predictions</h3>
                <p class="card-text fs-lg">Ultra accurate inference on network-wide operations</p>
                {/* <a href="#" class="btn btn-sm btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div class="block wide">
            <div class="card card-hover">
              <div class="card-body">
                <h3 class="card-title">Data Warehouse
                  Agnostic</h3>
                <p class="card-text fs-lg">Works with most legacy and modern enterprise data
                  warehouses</p>
                {/* <a href="#" class="btn btn-sm btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div class="block tall">
            <div class="card card-hover">
              <div class="card-body">
                <h3 class="card-title">Self-Healing,
                  Self-Learning
                  System</h3>
                <p class="card-text fs-lg">Robust multi-agent learning system requires minimal to no manual intervention</p>
                {/* <a href="#" class="btn btn-sm btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div class="block">
            <div class="card card-hover">
              <div class="card-body">
                <h3 class="card-title">Scalable AI</h3>
                <p class="card-text fs-lg">The power of scalable cloud compute
                  brings new hubs online swiftly</p>
                {/* <a href="#" class="btn btn-sm btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div class="block">
            <div class="card card-hover">
              <div class="card-body">
                <h3 class="card-title">Dynamic UI</h3>
                <p class="card-text fs-lg">Users have full control over their
                  bespoke performance metrics</p>
                {/* <a href="#" class="btn btn-sm btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div class="block extra-wide">
            <div class="card card-hover">
              <div class="card-body">
                <h3 class="card-title">Comprehensive Service</h3>
                <p class="card-text fs-lg">Fully guided implementation, maintenance, and continuous
                  feature updates included with subscriptions</p>
                {/* <a href="#" class="btn btn-sm btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
        </div>
        </div>
      </>
    );
  }
}

export default Features;
