import React, { Component } from "react";
import ".././css/theme.css";
import "./about.css";
import background from ".././assets/img/about/cover.jpg";
import BreadCrumb from "./breadcrumb";
import CompanyInfo from "./companyinfo";
import EndBar from "./../global/endbar";
import Stats from "./stats";
import People from "./people";
import Contact from "./contact";
import Splash from "./splash";
import NavBar from "./../global/navbar";
import Speaker from "./speaker";
import Demo from "./demo";

class About extends Component {
  render() {
    return (
      <>
        <title>Avi.AI Inc. | About Us</title>
        <NavBar />
        <div class="jarallax d-none d-md-block" data-jarallax data-speed="0.35">
          <span class="position-absolute top-0 start-0 w-100 h-100 bg-gradient-primary-translucent"></span>
          <Splash />
        </div>
        <BreadCrumb />
        <section class="container pb-5 mb-md-2 mb-lg-4">
          <CompanyInfo />
        </section>
        {/* <section class="container pb-5 mb-2 mb-md-4 mb-lg-5">
          <Stats />
        </section> */}
        {/* <section class="container-fluid pt-lg-2 pb-5 mb-2 mb-md-4 mb-lg-5">
          <People />
        </section> */}
        <section id="speakers" class="container py-5 my-2 my-md-4 my-lg-5">
          <Speaker />
        </section>
        {/* <section class="container pb-5 mb-2 mb-md-4 mb-lg-5">
          <Contact />
        </section> */}
        <section className="container pt-3 pb-4 pb-md-5">
          <Demo />
        </section>
        <EndBar />
      </>
    );
  }
}

export default About;
