import "./App.css";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Landing from "./landing/landing";
import errorV1 from "./single/error-v1";
import Touch from "./single/touch";
import "./css/theme.css";
import About from "./about/about";

class App extends Component {
  componentDidMount() {
    console.log("App Functionally Works/Easter Egg");
  }

  render() {
    return (
      <div>
        <Switch>
          <Route path="/" exact component={Landing} />
          <Route path="/home" exact component={Landing} />
          <Route path="/about" exact component={About} />
          <Route path="/contact" component={Touch} />
          <Route path="*" component={errorV1} />
        </Switch>
      </div>
    );
  }
}

export default App;
