import React, { Component } from "react";
import ".././css/theme.css";
import "./about.css";
import ".././assets/vendor/boxicons/css/boxicons.min.css";
import ".././assets/vendor/swiper/swiper-bundle.min.css";
import ".././assets/vendor/lightgallery/css/lightgallery-bundle.min.css";

class BreadCrumb extends Component {
  render() {
    return (
      <nav class="container py-4 mb-2 my-lg-3" aria-label="breadcrumb">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item">
            <a href="/">
              <i class="bx bx-home-alt fs-lg me-1"></i>Home
            </a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            About
          </li>
        </ol>
      </nav>
    );
  }
}

export default BreadCrumb;
