import React, { Component } from "react";
import ".././css/theme.css";
import "./about.css";
import ".././assets/vendor/boxicons/css/boxicons.min.css";
import ".././assets/vendor/swiper/swiper-bundle.min.css";
import ".././assets/vendor/lightgallery/css/lightgallery-bundle.min.css";

class CompanyInfo extends Component {
  render() {
    return (
      <>
        <h1 class="border-bottom pb-4">Manifesto</h1>
        <div class="row pt-2 pt-md-3">
          <div class="col-md-6">
            <p class="fs-lg pe-lg-4 mb-1 mb-lg-4">

            Manifesto
In the fast-paced world of air travel, we’re applying sophisticated artificial intelligence to solve the persistent challenge of baggage mishandling. Our technology replaces manual processes with a seamless, automated flow, orchestrated by algorithms that track and direct luggage with unprecedented accuracy.

Our mission is simple: employ advanced AI to eliminate lost luggage, using real-time data analytics to understand and manage the complexities of baggage logistics.
            </p>
          </div>
          <div class="col-md-6">
            <p class="fs-lg ps-lg-4 mb-1 mb-lg-6">
            We’re crafting a system where every bag's journey is monitored and optimized from departure to arrival.

Our AI doesn’t just process data—it anticipates patterns and prevents mishaps before they occur, ensuring that no computational resource is squandered. It's a deep dive into the vast pool of travel data, extracting efficiency and insights to refine the movement of every piece of luggage.

This is The Baggage Brain Project: an intelligent framework redefining baggage handling, making misplaced luggage an issue of the past and ensuring a smooth travel experience for all.






            </p>
          </div>
        </div>
      </>
    );
  }
}

export default CompanyInfo;
