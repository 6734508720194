import React, { Component } from "react";
import ".././css/theme.css";
import "./landing.css";
import ".././assets/vendor/boxicons/css/boxicons.min.css";
import ".././assets/vendor/swiper/swiper-bundle.min.css";


class System extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        console.log("System");
    }

    render() {
        return (
            <>
                <div class="d-flex flex-md-row flex-column">
                    <div class="clarity-column" style={{ alignSelf: 'center', paddingBottom: '1rem' }}>
                        <p class="fs-4 d-lg-block mb-2">PREFLIGHT</p>
                        <h1 className='display-5'>Clarity is Key</h1>
                        <p class='fs-5'>Our implementation process thrives on transparency.</p>
                        {/* <p class='fs-4'>A clear procedure is essential to our collaborative and curious culture.</p> */}
                    </div>
                    <div class="steps steps-sm steps-vertical steps-column" style={{ alignSelf: 'center', paddingBottom: '1rem' }}>

                        <div class="step">
                            <div class="step-number text-primary">
                                <div class="step-number-inner">
                                    <i class="bx bx-data fs-1"></i>
                                </div>
                            </div>
                            <div class="step-body">
                                <h5 class="fs-3 mb-2">Data Consolidation</h5>
                                <p class="fs-lg mb-0">Partner data delivery to Avi.AI</p>
                            </div>
                        </div>

                        <div class="step">
                            <div class="step-number text-primary">
                                <div class="step-number-inner">
                                    <i class="bx bx-layout fs-1"></i>
                                </div>
                            </div>
                            <div class="step-body">
                                <h5 class="fs-3 mb-2">Data Implementation</h5>
                                <p class="fs-lg mb-0">Data ingestion, mapping, transformation, and validation. Technical and methodology training. Hosting environment setup and defect management</p>
                            </div>
                        </div>

                        <div class="step">
                            <div class="step-number text-primary">
                                <div class="step-number-inner">
                                    <i class="bx bx-cog fs-1"></i>
                                </div>
                            </div>
                            <div class="step-body">
                                <h5 class="fs-3 mb-2">System Setup</h5>
                                <p class="fs-lg mb-0">Initial model training and tuning. Preliminary outputs and performance reviews. Initial user training</p>
                            </div>
                        </div>

                        <div class="step">
                            <div class="step-number text-primary">
                                <div class="step-number-inner">
                                    <i class="bx bx-wrench fs-1"></i>
                                </div>
                            </div>
                            <div class="step-body">
                                <h5 class="fs-3 mb-2">Testing & Training</h5>
                                <p class="fs-lg mb-0">Acceptance testing of ingestion pipeline; Final user
                                    training</p>
                            </div>
                        </div>

                        <div class="step">
                            <div class="step-number text-primary">
                                <div class="step-number-inner">
                                    <i class="bx bx-rocket fs-1"></i>
                                </div>
                            </div>
                            <div class="step-body">
                                <h5 class="fs-3 mb-2">Performance Validation & Launch</h5>
                                <p class="fs-lg mb-0">Performance and output review; Partner acceptance</p>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}

export default System;
